@mixin transition {
  transition: all 0.3s ease-in-out;
}

* {
  outline: none !important;
}

.pointer {
  cursor: pointer;
}

html,
body {
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, system-ui, SFUI, HelveticaNeue, Helvetica, Arial,
    sans-serif;
  color: #333;
}

a {
  @include transition;

  color: map-get($colors, greylight);
  text-decoration: none;

  &:hover {
    color: map-get($colors, primary);
  }
}

.form-floating:not(.ng-invalid) {
  color: darken(map-get($colors, greylight), 15);
}

// BUG ON BOOTSTRAP 5.2
// .tooltip {
//   position: absolute;
// }

// .tooltip-arrow {
//   position: absolute;
// }

.nav-link {
  color: map-get($colors, greylight);
}

.dropdown {
  &-item {
    cursor: pointer;

    &:active {
      background-color: map-get($colors, primary);

      & > i {
        color: white !important;
      }
    }
  }
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
.ngx-select__search.form-control {
  font-size: 16px;
  color: map-get($colors, primary) !important;
  padding-left: 7px !important;
}

.form-control {
  box-shadow: 0 5px 16px rgba(8,15,52,.07);
  border-radius: 5px;
  
  @include transition;

  &:focus {
    background-color: rgba(map-get($colors, primary), 0.1);
    border-color: map-get($colors, primary);
    box-shadow: none;
  }

  &.ng-invalid.ng-touched {
    color: map-get($colors, danger);
    background-color: rgba(map-get($colors, danger), 0.1);
    border-color: map-get($colors, danger);
  }

  &.ng-invalid.ng-touched2 {
    color: #ea2e49;
    background-color: rgba(234, 46, 73, 0.1);
    border-color: #ea2e49;
  }
}

.required:after {
  content: ' *';
  color: map-get($colors, danger);
  font-size: 80%;
  font-weight: 400;
}

.page-item {
  .page-link {
    color: map-get($colors, primary);
    border: none;
  }

  &.active .page-link {
    border-color: map-get($colors, primary);
    background: map-get($colors, primary);
  }
}

.pages {
  &-full {
    min-height: 100vh;
  }
}

// OnSite theme
.ohq {
  &-bg {
    &-primary {
      background-color: map-get($colors, primary);
      color: white;
    }
  }

  &-textarea {
    height: 100px !important;
  }

  &-sticky-margin {
    top: 6.5rem;
    z-index: 1019;
  }

  &-header {
    &--sticky {
      margin: 0 -24px;
      padding: 1rem;
      background-color: white;
      box-shadow: 0px 15px 10px -15px rgba(map-get($colors, greylight), 0.15);
    }
  }

  &-avatar {
    display: inline-block;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-size: cover;
    background-position: center;
    vertical-align: middle;

    &--small {
      width: 30px;
      height: 30px;
    }

    &--large {
      width: 80px;
      height: 80px;
    }
  }

  &-toastr {
    box-shadow: 0 0 10px rgba(map-get($colors, greylight), 0.15) !important;
    padding: 1rem !important;
    margin: 0 1rem 1rem !important;
    background-image: none;

    &.toast-success {
      background-color: map-get($colors, success) !important;
      background-image: none !important;
    }

    &.toast-danger {
      background-color: map-get($colors, danger) !important;
      background-image: none !important;
    }
  }

  &-text {
    &-highlight {
      font-weight: 700;
      color: map-get($colors, primary) !important;
    }

    &-success {
      color: map-get($colors, success);
    }

    &-primary {
      color: map-get($colors, primary);
    }

    &-warning {
      color: map-get($colors, warning);
    }

    &-danger {
      color: map-get($colors, danger);
    }

    &-break {
      word-break: break-word;
      white-space: normal !important;
    }
  }

  &-badge {
    color: white;

    &-primary {
      background-color: map-get($colors, primary);
    }

    &-light {
      background-color: map-get($colors, light);
    }

    &-success {
      background-color: map-get($colors, success);
    }

    &-warning {
      background-color: map-get($colors, warning);
    }

    &-danger {
      background-color: map-get($colors, danger);
    }

    &-recurrence {
      &-h {
        background-color: lighten(blue, 15%);
      }

      &-o {
        background-color: lighten(blue, 20%);
      }

      &-d {
        background-color: lighten(blue, 25%);
      }

      &-w {
        background-color: lighten(blue, 30%);
      }

      &-m {
        background-color: lighten(blue, 35%);
      }

      &-y {
        background-color: lighten(blue, 40%);
      }
    }
  }

  &-btn {
    box-shadow: 0 5px 16px rgba(8,15,52,.07);
    @include transition;

    &-group > .btn {
      box-sizing: border-box;

      :last-child:hover {
        border: 0 !important;
      }

      &:first-child {
        border-right: 1px solid darken(map-get($colors, primary), 10);
      }
    }

    &-outline {
      &-primary {
        color: map-get($colors, primary);
        border-color: map-get($colors, primary);

        &:hover {
          color: white;
          background-color: map-get($colors, primary);
        }
      }
    }

    &-link {
      color: map-get($colors, primary);

      &:hover {
        color: map-get($colors, secondary);
      }
    }

    &-primary {
      background: map-get($colors, primary) !important;
      border-color: map-get($colors, primary) !important;
      color: white !important;

      &:focus,
      &:active,
      &:disabled,
      &:focus:active,
      &:visited {
        border-color: map-get($colors, primary);
        background: darken(map-get($colors, primary), 1%);
      }

      &:not(.disabled):hover {
        background: darken(map-get($colors, primary), 5%);

        &,
        & > i {
          color: white;
        }
      }
    }

    &-secondary {
      background: white !important;
      color: map-get($colors, dark) !important;

      &:hover,
      &:active,
      &:disabled,
      &:focus:active {
        background: map-get($colors, primary) !important;
        border-color: map-get($colors, primary) !important;
        color: white !important;
      }
    }

    &-third {
      background: white !important;
      color: map-get($colors, primary) !important;
      border: 1px solid map-get($colors, primary);

      &:hover,
      &:focus,
      &:active,
      &:disabled,
      &:focus:active {
        background: map-get($colors, primary) !important;
        border-color: map-get($colors, primary) !important;
        color: white !important;
      }
    }

    &-dark {
      color: white !important;
      background: map-get($colors, dark) !important;
      border-color: map-get($colors, dark) !important;

      &:hover,
      &:focus,
      &:active,
      &:disabled,
      &:focus:active {
        background: map-get($colors, dark) !important;
        border-color: map-get($colors, dark) !important;
      }
    }

    &-warning {
      background: map-get($colors, warning);
      border-color: map-get($colors, warning);

      &:hover,
      &:focus,
      &:active,
      &:disabled,
      &:focus:active {
        color: white !important;
      }
    }

    &-success {
      background: map-get($colors, success);
      border-color: map-get($colors, success);
      color: white !important;

      &:hover,
      &:focus,
      &:active,
      &:disabled,
      &:focus:active {
        background: darken(map-get($colors, success), 10%) !important;
        border-color: darken(map-get($colors, success), 10%) !important;
      }
    }

    &-danger {
      background: map-get($colors, danger);
      border-color: map-get($colors, danger);
      color: white !important;

      &:hover,
      &:focus,
      &:active,
      &:disabled,
      &:focus:active {
        background: darken(map-get($colors, danger), 10%) !important;
        border-color: darken(map-get($colors, danger), 10%) !important;
      }
    }
  }

  &-badge-primary,
  &-badge-danger,
  &-badge-warning,
  &-badge-success {
    color: white;
  }

  &-badge-primary {
    background: map-get($colors, primary);
  }

  &-badge-danger {
    background: map-get($colors, danger);
  }

  &-badge-success {
    background: map-get($colors, success);
  }

  &-badge-warning {
    background: map-get($colors, warning);
  }

  &-card {
    &-charts {
      height: 300px;

      &-content {
        height: calc(300px - 2.7rem);
      }
    }

    &-header,
    &-footer {
      background-color: white;
    }

    &-header {
      text-transform: capitalize;
    }

    &-footer {
      text-transform: uppercase;
    }

    &--opacity {
      opacity: 0.7;
    }
  }

  &-square-card {
    display: inline-flex;
    padding: 0;
    justify-content: center;
    width: 12rem;
    height: 12rem;
  }

  &--text-ellipsis {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    &--large {
      max-width: 250px;
    }
  }
}

tabset {
  .nav-pills {
    .nav-link {
      &:hover {
        color: map-get($colors, primary);
      }

      &.active {
        background-color: rgba(map-get($colors, primary), 0.15);
        color: map-get($colors, primary);
      }
    }

    .nav-item {
      margin-right: 0.5rem;
    }
  }
}

// Google search place
.pac-container {
  z-index: 9999 !important;

  &:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

    background-image: none !important;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.ngx-select_ {
  &_item_active,
  &_selected-plural {
    background-color: map-get($colors, primary) !important;
  }

  &_selected-plural {
    border-color: map-get($colors, primary) !important;
  }

  &_clear-icon {
    color: white !important;
  }
}

.ngx-select__search.form-control{
  height: 32px !important;
}

.vacation ngx-select .ngx-select .form-control {
  height: 58px !important;
  padding: 15px;
}

.py-4 {
  padding-bottom: 4.5rem!important;
}

.dropdown-menu {
  box-shadow: 0 5px 16px rgba(8,15,52,.07);
  z-index: 99999;
}
.ohq-dropzone-task {
  background: none !important;
  padding-bottom: 1.5rem!important;
}