app-protected {
  .protected {
    position: relative;

    &-content {
      position: absolute;
      width: 100%;
      min-height: 100vh;

      
      @include media-breakpoint-up(md) {
        width: calc(100% - 250px);
        left: 250px;
      }
    }

    &-sidebar {
      background-color: #2e323a;
      position: fixed;
      // width: 250px;
      height: 100vh;
    }
  }
}

@include media-breakpoint-up(md) {
  .expand-content {
    width: calc(100% - 70px) !important;
    left: 70px !important;
  }
}
