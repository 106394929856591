// Picture upload
.picture {
  &-label {
    position: relative;
    cursor: pointer;
  }

  &--isUploading {
    opacity: 0.5;
  }

  &-description {
    @include transition;

    background: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.2rem 0.6rem 0.8rem 0.6rem;
    font-size: 0.6rem;
    text-align: center;
    border-radius: 0 0 3rem 3rem;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  &-description-square {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.2rem 0.6rem 0.8rem 0.6rem;
    font-size: 0.6rem;
    text-align: center;
    height: 50px;
    &:hover {
      opacity: 1;
    }
  }

  #pictureInput {
    display: none;
  }
}
