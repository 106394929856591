$fa-font-path: '~font-awesome/fonts';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900&display=swap');
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
@import 'bootstrap/scss/bootstrap';
@import 'font-awesome/scss/font-awesome';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'ngx-ui-switch/ui-switch.component';
@import 'ngx-toastr/toastr.css';

@import 'animate-scss/_properties';
@import 'animate-scss/_attention-seekers/_shake';
@import 'animate-scss/_attention-seekers/_flash';
@import 'animate-scss/_fading-entrances/_fadeIn';

@import './assets/fonts/linearicons/linearicons';

@import './app/shared/styles/app.component';
@import './app/shared/styles/animations.component';
@import './app/shared/styles/checklist.component';
@import './app/shared/styles/dashboard.component';
@import './app/shared/styles/datepicker.component';
@import './app/shared/styles/maps.component';
@import './app/shared/styles/navbar.component';
@import './app/shared/styles/picture.component';
@import './app/shared/styles/protected.component';
@import './app/shared/styles/safari.component';
@import './app/shared/styles/sidebar.component';
@import './app/shared/styles/topbar.component';

@import './app/shared/components/modals/modals.component';
@import './app/shared/components/modals/import/modals.import.component.scss';
@import './app/shared/components/content/content.component';
@import './app/shared/components/loading/loading.component';
@import './app/shared/components/table/table.component';
@import './app/shared/components/typeahead/typeahead.component';

.comments {
  margin-top: 20px;
}

.comments-title {
  font-size: 30px;
  margin-bottom: 20px;
}

.comments-container {
  margin-left: -12px;
}

.comment-form-title {
  font-size: 22px;
}

.comment-form-textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  margin-top: 8px;
  // border: 1px solid rgb(107, 114, 12);
}

.comment-form-button {
  font-size: 16px;
  padding: 8px 16px;
  background: map-get($colors, primary);
  border-radius: 5px;
  color: white;
}

.comment-form-button:hover:enabled {
  cursor: pointer;
  background: map-get($colors, primary);
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-form-cancel-button {
  margin-left: 10px;
}

.comment {
  display: flex;
  margin-bottom: 28px;
}

.comment-image-container {
  margin-right: 12px;
}

.comment-image-container img {
  border-radius: 50px;
}

.comment-right-part {
  width: 100%;
}

.comment-content {
  display: flex;
}

.comment-author {
  margin-right: 8px;
  font-size: 16px;
  color: #212529;
  font-weight: 600;
  // color: map-get($colors, primary);
}

.comment-text {
  background: #f4f4f4;
  padding: 7px;
  max-width: 100%;
  overflow-x: hidden;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid #fbfbfb;
  display: block;
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  margin-top: 8px;
}

.comment-action {
  margin-right: 8px;
}

.comment-action:hover {
  text-decoration: underline;
}

.replies {
  margin-top: 20px;
}

.card {
  box-shadow: 0 5px 16px rgba(8, 15, 52, 0.07);
  border-radius: 16px;
  overflow: hidden;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.title-bold {
  font-weight: 500;
}

.card-header:first-child {
  font-weight: 500;
}

.card-footer:last-child {
  padding: 1rem 1rem;
}

.link {
  color: map-get($colors, primary);
}

.text-right {
  text-align: right;
}

.fc-scrollgrid,
.fc-list-table {
  background: white !important;
}

.fc-v-event {
  background-color: map-get($colors, primary);
  border: 1px solid map-get($colors, primary);
  display: block;
}

.fc .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid
    map-get($colors, primary);
}

.fc .fc-button-primary {
  background-color: map-get($colors, primary);
  border: 1px solid map-get($colors, primary);
}
.fc .fc-button-primary:disabled {
  background-color: map-get($colors, primary);
  border: 1px solid map-get($colors, primary);
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: map-get($colors, secondary);
  border: 1px solid map-get($colors, secondary);
}

.fc-h-event {
  background-color: map-get($colors, primary);
  border: 1px solid map-get($colors, primary);
}

.blue-status {
  background-color: #4d4dff;
  color: white;
}
.text-white {
  color: white;
}

.open-badge {
  background-color: #006af8b5;
  color: white;
  border: 1px solid #006af8b5;
}

.background-dark {
  background-color: #000;
  color: white;
}

.upload-files-tasks {
  display: none !important;
}
.modal-content {
  border-radius: 18px;
}

.fc .fc-daygrid-event {
  margin-top: 1px;
  z-index: 6;
  background: #74a4fc;
  color: white;
}

.fc-daygrid-dot-event,
.fc .fc-daygrid-event,
.fc .fc-daygrid-event-harness-abs {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ohq--text-ellipsis-file {
  max-width: auto !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.badge-validated {
  opacity: 1 !important;
}

.ql-editor {
  min-height: 400px !important;
}

.bs-timepicker-field {
  height: 56px;
}

.timezone-email {
  padding: 15px;
}

.float-left {
  float: left;
}

.editor {
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: 4px;
  min-height: 400px;

  .NgxEditor__MenuBar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .NgxEditor {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
    min-height: 400px;
    height: 100%;
  }

  .NgxEditor__Content {
    min-height: 400px;
    padding: 8px;
    height: 100%;
  }

  .CodeMirror {
    border: 1px solid #eee;
    height: auto;
    margin-bottom: 0.7rem;

    pre {
      white-space: pre !important;
    }
  }
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0;
  max-height: 45px;
  height: auto;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.card-no-hidden {
  overflow: unset !important;
}


.img-large-thumbnail {
  max-height: none !important;
}
.g-recaptcha {
  margin-top: 15px;  /* Add some spacing around the reCAPTCHA */
  display: flex;
  justify-content: center; /* Center the widget horizontally */
  align-items: center;     /* Ensure it's centered vertically if needed */
}

.mb-15 {
  margin-bottom: 15px;
}