typeahead-container {
  visibility: visible !important;

  .dropdown-item.active,
  .dropdown-item:active {
    background: map-get($colors, primary);
  }
  
}
.red {
  color: red
}
