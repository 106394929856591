.ohq-table {
  margin-bottom: 0;

  & > :not(:first-child) {
    border-top: 0;
  }

  tr {
    @include transition;
  }

  td.avatar {
    width: 50px;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    & > * {
      --bs-table-accent-bg: rgba(0, 0, 0, 0.01) !important;
    }

    &:hover {
      @include transition;
      background-color: rgba(map-get($colors, primary), 0.05);
    }
  }

  &--hover tbody tr:hover {
    @include transition;

    background-color: rgba(map-get($colors, primary), 0.05);
  }

  &-title {
    padding: 1.5rem 1rem;
    border-bottom: 1px solid map-get($colors, light);
  }

  th,
  td {
    border: 0 !important;
    padding: 1rem;
    white-space: nowrap;
  }

  thead th {
    @include transition;

    font-weight: normal;
    color: rgba(0, 0, 0, 0.4) !important;
    cursor: pointer;

    &.active,
    &:hover {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03) !important;
    cursor: pointer;

    td:not(.ohq-text--highlight) {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.img-not-found {
  margin: 0 auto;
  width: 20% !important;
}
