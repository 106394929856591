.loading {
  &.full-size {
    height: 100vh;
  }
  .msg {
    color: map-get($colors, primary);
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    // border-right: .15em solid map-get($colors, primary); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    margin-bottom: 15px;
    width: 100%;
  }
  .chart {
    margin-top: 120px !important;
  }
  .small-chart {
    margin-top: 85px !important;
  }
  .spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;

    div {
      width: 18px;
      height: 18px;
      background-color: map-get($colors, primary);
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}